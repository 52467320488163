
import { defineComponent, onBeforeMount, reactive, ref, toRaw, toRefs } from "vue"

import LayoutDefault from "@/layout/LayoutAdmin.vue"
import Loading from "@/components/Loading.vue"
import Toast from "@/components/Toast.vue"

import bannersService from "@/services/bannersService"
import { uploadFile } from "@/services/fileService"

export default defineComponent({
    components: {
        LayoutDefault,
        Loading,
        Toast,
    },

    setup() {
        const state = reactive({
            sections: [],
            status: "loading",
            message: ""
        })

        const image = ref<HTMLInputElement>(null)

        const banner = reactive({
            title: "",
            description: "",
            price: "",
            section: "",
            link: "",
        })

        async function fetchSections() {
            state.status = "loading"

            try {
                const response = await bannersService.getBannersSections()

                if (response.error) {
                    console.error(response)
                    
                    state.status = "error"
                    state.message = "Oops... algo ha fallado"
                }
                
                else {
                    state.sections = response
                    state.status = ""
                }
            }

            catch (err) {
                console.error(err)
                // LOG
            }
        }

        onBeforeMount(async function () {
            await fetchSections()
        })

        function clearForm() {
            banner.title = ""
            banner.description = ""
            banner.price = ""
            banner.section = ""

            image.value.value = ""
        }

        async function save() {
            try {
                state.status = "loading"

                // UPLOAD FILE

                const file = image.value.files[0]
                const img = await uploadFile(file)

                const response = await bannersService.createBannersItem({ ...toRaw(banner), img })

                if (response.error) {
                    state.status = "error"
                    state.message = "Algo ha fallado, intente nuevamente"

                    console.warn(response.error)
                }

                else {
                    state.status = "success"
                    state.message = "Contenido registrado con exito"

                    clearForm()
                }
            }

            catch (err) {
                console.error(err)
                // LOG
            }
        }

        return {
            state,
            ...toRefs(banner),

            save,
            image,
        }
    }
})
